
    import Vue from "vue";
    import Component from "vue-class-component";
    import { StateChanger } from "vue-infinite-loading";
    import ApiButton from "@/components/ApiButton.vue";
    import { required } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import * as toastr from "toastr";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import { NotificationSearchParameters } from "@/model/NotificationSearchParameters";
    import { LookupItem } from "@/model/LookupItem";
    import { Notification, INotification } from "@/model/Notification";
    import { TriState } from "@/model/Enums";
    
    @Component({
        components: { ApiButton }
    })
    export default class Notifications extends Vue {

        //
        // -- properties
        //

        searchParameters = new NotificationSearchParameters();
        totalCount = -1;
        notificationList: Array<INotification> = [];
        readonly notification = new Notification();

        // computed
        get dialogueTitle(): string {
            if (!this.notification) return "- - -";
            if (utils.isEmptyId(this.notification.id)) return "New Notification";
            return "Edit " + this.notification.description;
        }

        get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        private get isDeletedSearchOptions(): Array<LookupItem> { return [
            new LookupItem({ id: TriState.UseDefault, description: "All", isArchived: false }),
            new LookupItem({ id: TriState.True, description: "Deleted", isArchived: false }),
            new LookupItem({ id: TriState.False, description: "Not deleted", isArchived: false })
        ];}

        //
        // -- methods
        //

        async edit(notificationData: INotification) {
            this.notification.update(notificationData);
            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("notificationDialogue");
			// refresh from server
            const serverNotificationData = await apiClient.get(`api/notification/Load?id=${notificationData.id}`);
            this.notification.update(serverNotificationData);
        }

        editNew() {
            utils.resetObject(this.notification);
            this.$v.$reset();
            this.$bvModal.show("notificationDialogue");
        }

        cancel() {
            this.$bvModal.hide("notificationDialogue");
            utils.resetObject(this.notification);
        }

        async save(event: Event) {

            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.notification.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            const response: { newID: number } =  await apiClient.post("/api/notification/save", this.notification, event);
            if (this.notification.isNew) {
                this.notification.id = response.newID;
            }
            toastr.success("Saved");           
            this.$bvModal.hide("notificationDialogue");
            // redo search - or should we just update in place?
            this.refreshSearch();
        }

        // 'delete' is a reserved word
        async deleteItem(event: Event) {
            if (this.notification.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.notification.description + "'?", {
                title: "Delete",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/notification/delete", this.notification, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("notificationDialogue");
            this.refreshSearch();
        }

        refreshSearch(event?: Event) {
            this.notificationList = [];
            this.searchParameters.pageNumber = 1;
            this.totalCount = -1;
            this.search(event);
        }

        async infiniteLoadingHandler(stateChanger: StateChanger) {
            if (this.notificationList.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                return;
            }
            this.searchParameters.pageNumber += 1;
            await this.search();
            if (this.notificationList.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }           
        }

        private async search(event?: Event) {
            const response = await apiClient.post("/Api/Notification/Search", this.searchParameters, event);
            if (this.searchParameters.pageNumber === 1) {
                this.totalCount = response.count;
            }
            this.notificationList.push(...response.list.map((s: INotification) => new Notification(s)));
        }

        @Validations()
        validations() {
            const validations = {
                notification: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.notification.text = { required };
            return validations;
        }
    }
